<template>
  <div class="px-7 py-4" style="background: #f7f7f7; height: auto">
  <v-col cols=12>
    <img src="@/assets/thingsToDo/WTH1.jpg" 
    style="width:100%" />
    </v-col>
  </div>
</template>
<script>
export default {
  name: "WelcomeDashboard",
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
};
</script>
<style scoped>
.active-class {
  border-right: 4px solid #38227a;
  border-left: 4px solid #38227a;
  border-radius: 4px;
}
.card-datatable.v-card {
  margin-bottom: -2px;
  display: flex;
}
.text-capitalize.v-btn {
  position: absolute;
  right: 10px;
  letter-spacing: 0px;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
.alignSelf-center {
  align-self: center;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.ONGOING {
  margin-top: 10px;
  background-color: rgba(231, 111, 0, 0.12);
  color: #e76f00;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.UPCOMING {
  margin-top: 10px;
  background-color: rgba(0, 133, 255, 0.12);
  color: #0085ff;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.COMPLETED {
  margin-top: 10px;
  background-color: rgba(6, 118, 5, 0.12);
  color: #067605;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.DRAFT {
  margin-top: 10px;
  border-radius: 10px;
  font-weight: 700;
}
.footer-card.v-card {
  max-height: 70px;
  margin-top: 0px;
  border-top: 1px solid #e6e6e6;
}
.footer-input.v-input {
  width: 60px;
  padding: 0%;
}
.footer_p {
  margin-left: 25px;
  margin-right: 15px;
  margin-top: 8px;
  color: black;
}
.trow {
  height: 50px;
  background-color: #eeeeee;
  width: 100%;
}
.theader {
  font-weight: 400;
  font-size: 13px;
  padding: 12px;
  text-align: center;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  font-family: Lato;
  border-radius: 0px;
  min-width: 1500px;
}
.table-div {
  overflow-x: scroll;
}

.v-btn {
  font-family: Lato;
  font-weight: 600;
}
.v-input {
  font-family: Lato;
}
.pagination {
  float: right;
}
@media (min-width: 0px) and (max-width: 600px) {
  .pagination {
    float: none;
  }
  .footer-card.v-card {
    max-height: 200px;
    align-items: center;
    text-align: center;
  }
  .footer_p {
    margin-left: 60px;
  }
  .v-input.search-input {
    display: flex;
    width: 240px;
    margin-left: 10px;
  }
  .v-icon.notranslate.mobile-menu {
    position: absolute;
    top: 25px;
  }
  .table-div {
    overflow: scroll;
  }
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
